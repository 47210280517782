import { IoAddOutline } from "react-icons/io5";
import { generalObj } from "../../constant/objects_types";
import { EditIconSvg, TrashSvg } from "../../styles/IconStyle";
import BorderDivider from "../BorderDivider";
import InputField from "../InputField";
import PickerModal from "../modal/PickerModal";
import SellPriceModal from "../modal/SellPriceModal";
import { useEffect, useRef, useState } from "react";
import ScanningPeople from "./ScanningPeople";
import ProfileGenerator from "../ProfileGenerator";
import { altCalculateSellingPrice, calculateSellingPrice, slugify } from "../../action/generalFunc";
import TicketNameModal from "../modal/TicketNameModal";

const NewEventTicket = ({ item, editting, onChange }:{
    item: generalObj, 
    editting?: boolean,
    onChange: (name:string, val:any) => void,
}) => {

    const [sellingDetail, setSellingDetail] = useState<boolean>(false);

    const ticket_form:generalObj = {
        vvip: {
            id:'01',
            title: 'V.V.I.P',
            slug:'vvip',
            price:"",
            symbol: '₦',
            description: '',
            ticketNum:"",
            isDeleted: false
        },
        vip: {
            id:'02',
            title: 'V.I.P',
            slug:'vip',
            price:"",
            symbol: '₦',
            description: '',
            ticketNum:"",
            isDeleted: false
        },
        regular: {
            id:'03',
            title: 'Regular',
            slug:'regular',
            price:"",
            symbol: '₦',
            description: '',
            ticketNum:"",
            isDeleted: false
        }
    }
    const [openAddPicker, setOpenAddPicker] = useState<boolean>(false);
    const [addTicketCat, setAddTicketCat] = useState<generalObj[] | null>(null);
    const [selectedAdd, setSelectedAdd] = useState<generalObj | null>(null);
    const [canScan, setCanScan] = useState<generalObj[]>([]);
    const [showScanner, setShowScanner] = useState<boolean>(true);
    const [openPeople, setOpenPeople] = useState<boolean>(false);
    const [editLoaded, setEditLoaded] = useState<boolean>(false);
    const [showTicketRename, setShowTicketRename] = useState<boolean>(false);
    const [ticketRenameObj, setTicketRenameObject] = useState<generalObj | null>(null);

    const parentRef = useRef<HTMLDivElement | null>(null);

    // const updateUserScan = useCallback(() => {
    //     onChange("canScan", canScan);
    // }, [canScan, onChange]);

    useEffect(() => {
        if (!editting || editLoaded) return;
        setCanScan(item.fields.canScan.filter((elem:generalObj | null) => {
            if (elem) {
                elem.selected = true;
            }
            return elem;
        }));
        setEditLoaded(true);
    }, [item, editting, editLoaded]);

    const changeCategory = (id:string | number, field:string, val:any) => {
        let error = false;
        let categories = item.fields.categories.filter((item:generalObj) => {
            if (item.id === id) {
                if (field === 'isDeleted' && item.canNotDelete) {
                    error = true;
                    return item;
                }
                item[field] = val
            }
            return item
        });
        if (error) {
            alert('Ticket has been purchased and cannot be deleted.');
        }
        onChange('categories', categories);
    }

    const changeNoCategory = (name:string, val:any) => {
        onChange('noCategory', {...item.fields.noCategory, [name]:val});
    }

    const updateTicketName = (val:string, ticketObj:generalObj) => {
        let slugify_name = slugify(val);
        changeCategory(ticketObj.id, 'title', val);
        changeCategory(ticketObj.id, 'slug', slugify_name);
        setTicketRenameObject(null);
        setShowTicketRename(false);
    }

    const updateNoTicketName = (val:string) => {
        let slugify_name = slugify(val);
        onChange('noCategory', {...item.fields.noCategory, 'title': val, 'slug': slugify_name});
    }

    const newTicketCat = () => {
        if (!item.fields.ticketCategory) return;
        let active_ticket:Array<string> = [];
        let result = [{
            id:'00',
            label:'--Please select--',
            value:''
        }];

        item.fields.categories.filter((item:generalObj) => {
            if (!item.isDeleted) {
                active_ticket.push(item.slug);
            }
            return item;
        })

        for (const [key, value] of Object.entries(ticket_form)) {
            if (!active_ticket.includes(`${key}`)) {
                result.push({
                    id:value.id,
                    label:value.title,
                    value:value.slug
                });
            }
        }

        result.push({
            id: `${Math.ceil(Math.random() * 1000)}`,
            label:"New Ticket",
            value: "new_ticket"
        });
                
        if (result.length > 0) {
            setAddTicketCat(result);
            setOpenAddPicker(true);
        }
    }

    const onTicketChange = (val:generalObj) => {
        setSelectedAdd(val);
    }

    const closeAddTicket = () => {
        if (!selectedAdd || selectedAdd.value === '') {
            setOpenAddPicker(false);
            setAddTicketCat(null);
            setSelectedAdd(null);
            return;
        }
        if (selectedAdd.value === 'new_ticket') {
            let new_tick = {
                id:selectedAdd.id,
                title: selectedAdd.label,
                slug: selectedAdd.value,
                price:"",
                symbol: '₦',
                description: '',
                ticketNum:"",
                isDeleted: false
            }
            onChange('categories', [...item.fields.categories, new_tick]);
        }else {
            if (item.fields.categories.filter(((item:generalObj) => item.slug === selectedAdd.value)).length > 0) {
                const edit_category = item.fields.categories.filter((item:generalObj) => {
                    if (item.slug ===  selectedAdd.value && item.isDeleted) {
                        item.isDeleted = false;
                    }
                    return item
                });
                
                onChange('categories', [...edit_category]);
            }else {
    
                onChange('categories', [...item.fields.categories, {...ticket_form[selectedAdd.value], id:Math.ceil(Math.random() * 1000000)}]);
            }
        }
        
        

        setOpenAddPicker(false);
        setAddTicketCat(null);
        setSelectedAdd(null);
    }

    const deleteScanningUser = (id:number) => {
        const updateScan = canScan.filter((cItem) => cItem.id !== id);
        setCanScan([...updateScan]);
        onChange("canScan", updateScan);
        // setCanScan((prevCanScan) => (
        //     prevCanScan.filter((cItem) => cItem.id !== id)
        // ));
    }
    return (
        <div style={{height:"100%"}} ref={parentRef}>
            <div className="inputField" style={{marginBottom:"24px"}}>
                <div className="inputLabel" style={styles.labelStyle}>Will this event be needing tickets</div>
                <div style={{display:'flex', marginTop:"10px", gap:"4px"}}>
                    <button className={`autoBtn greyAutoBtn ${item.fields.eventHasTicket && "fillAutoBtn"}`} style={{opacity:item.fields.eventHasTicket ? "1":"0.6"}} onClick={() => onChange('eventHasTicket', true)}>
                        <span className="autoBtnText">Yes</span>
                    </button>
                    <button className={`autoBtn greyAutoBtn ${!item.fields.eventHasTicket && "fillAutoBtn"}`} style={{opacity:!item.fields.eventHasTicket ? "1":"0.6"}} onClick={() => onChange('eventHasTicket', false)}>
                        <span className={"autoBtnText"}>No</span>
                    </button>
                </div>
            </div>

            {item.fields.eventHasTicket && (
                <>
                    <div className="inputField" style={{}}>
                        {/* <div className="inputLabel" style={styles.labelStyle}>Select the people who will be scanning tickets (Optional)</div> */}
                        <div style={{display:'flex', gap:"4px", justifyContent:'space-between'}}>
                            <div className="sectionTitle" style={{fontSize:"13px"}}>
                                Select Friends to scan ({canScan && canScan.length})
                            </div>
                            
                            <button onClick={() => setShowScanner(!showScanner)}>
                                <span className={"autoBtnText"} style={{color:"rgba(255, 255, 255, 0.60)", fontSize:"12px", fontWeight:"600"}}>{showScanner ? "Hide" : "Show"}</span>
                            </button>
                        </div>
                    </div>

                    {canScan && (
                        <>
                            {showScanner && (
                                <div>
                                    {canScan.map((item:generalObj, index) => (
                                        item && (
                                            <div key={index} style={{ display:"flex", justifyContent:'space-between', alignItems:'center', marginTop: index === 0? 0 : "10px"}}>
                                                <div style={{display:'flex', alignItems:'center'}}>
                                                    {item.profile_picture ? (
                                                        <img src={item.profile_picture} style={{width:"25px", height:"25px"}} className="profilePictureUser" alt="ticketFriend" />
                                                    ): (
                                                        <ProfileGenerator title={item.username ? item.username[0].toUpperCase() : item.fullname[0].toUpperCase()} constStyle={{width:"25px", height:"25px"}} textStyle={{fontSize:"13px", lineHeight:"19px"}} />
                                                    )}
                                                    <div style={{display:"flex", flexDirection:'column', marginLeft:"10px", alignItems:'flex-start'}}>
                                                        <div className="postOwnerText" style={{marginLeft:"0px", fontWeight:'600'}}>{item.fullname}</div>
                                                        {(item.username || item.fullname) && <div className="inputLabel" style={{color:'rgba(255, 255, 255, 0.6)'}}>{item.username ? item.username : item.fullname}</div>}
                                                    </div>
                                                </div>
                                                <button onClick={() => deleteScanningUser(item.id)}>
                                                    <TrashSvg />
                                                </button>
                                            </div>
                                        )
                                    ))}
                                </div>
                            )}
                            
                            <button style={{color:"#DB012E", fontSize:'12px', fontWeight:'600', lineHeight:'16px', display:'flex', gap:"5px", marginTop:"10px"}} onClick={() => setOpenPeople(true)}>
                                <IoAddOutline size={"16px"} color={"#DB012E"} />
                                <span>Add People</span>
                            </button>
                        </>
                    )}

                    <div className="inputField" style={{marginBottom:"18px", marginTop:"24px"}}>
                        <div className="inputLabel" style={styles.labelStyle}>User pays charges?</div>
                        <div style={{display:'flex', marginTop:"10px", gap:"4px"}}>
                            <button className={`autoBtn greyAutoBtn ${item.fields.UsersIncurCharge && "fillAutoBtn"}`} style={{opacity:item.fields.UsersIncurCharge ? "1":"0.6"}} onClick={() => onChange('UsersIncurCharge', true)}>
                                <span className="autoBtnText">Yes</span>
                            </button>
                            <button className={`autoBtn greyAutoBtn ${!item.fields.UsersIncurCharge && "fillAutoBtn"}`} style={{opacity:!item.fields.UsersIncurCharge ? "1":"0.6"}} onClick={() => onChange('UsersIncurCharge', false)}>
                                <span className={"autoBtnText"}>No</span>
                            </button>
                        </div>

                        <div className="inputLabel" style={{...styles.labelStyle, marginTop:'24px'}}>Will the ticket have categories?</div>
                        <div style={{display:'flex', marginTop:"10px", gap:"4px"}}>
                            <button className={`autoBtn greyAutoBtn ${item.fields.ticketCategory && "fillAutoBtn"}`} style={{opacity:item.fields.ticketCategory ? "1":"0.6"}} onClick={() => editting && !item.fields.ticketCategory ? null : onChange('ticketCategory', true)}>
                                <span className="autoBtnText">Yes</span>
                            </button>
                            <button className={`autoBtn greyAutoBtn ${!item.fields.ticketCategory && "fillAutoBtn"}`} style={{opacity:!item.fields.ticketCategory ? "1":"0.6"}} onClick={() => editting && item.fields.ticketCategory ? null : onChange('ticketCategory', false)}>
                                <span className={"autoBtnText"}>No</span>
                            </button>
                        </div>
                        {editting && (
                            <div style={{color:"#DB012E", fontSize:'12px', fontWeight:'600', lineHeight:'16px', display:'flex', gap:"5px", marginTop:"10px"}}>
                                Ticket category option cannot be changed
                            </div>
                        )}
                        {item.fields.ticketCategory && (
                            <div className="inputTextCont" style={{marginTop:"32px", display:'block'}}>
                                <div className="itemOwner" style={{fontWeight:'400', color:'rgba(255, 255, 255, 0.6)', marginBottom:"12px"}}>Ticket Categories</div>
                                {item.fields.categories.map((citem:generalObj, index:number) => (
                                    <div key={citem.id} style={{display:citem.isDeleted?'none':'block'}}>
                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                            <div className="postOwnerTextl" style={{marginLeft:0, fontWeight:"700"}}>{citem.title}</div>
                                            <div style={{display:'flex', gap:'10px'}}>
                                                <button onClick={() => {setTicketRenameObject(citem); setShowTicketRename(true);}}>
                                                    <EditIconSvg />
                                                </button>
                                                <button onClick={() => changeCategory(citem.id, 'isDeleted', true)}>
                                                    <TrashSvg />
                                                </button>
                                            </div>
                                        </div>
                                        <InputField id={`no_tickets${index}`} label='Number of Tickets' inputProp={{value:citem.ticketNum, type:'number', inputMode:"numeric"}} labelStyle={styles.labelStyle} onTextChange={(val) => changeCategory(citem.id, 'ticketNum', val)} />
                                        <InputField id={`price${index}`} label='Price (Optional)' inputProp={{value:citem.price, type:'number', inputMode:"numeric", placeholder:'19,400'}} customInputField={{paddingTop:0}} labelStyle={styles.labelStyle} onTextChange={(val) => changeCategory(citem.id, 'price', val)} />
                                        {/* Description */}
                                        <InputField id={`desc${index}`} label='Description (optional)' multiline={true} inputProp={{value:citem.description, maxLength:75}} customInputField={{paddingTop:0}} customInputText={{lineHeight:"20px", height:"50px", minHeight:"40px", maxHeight:"100px"}} labelStyle={styles.labelStyle} onTextChange={(val) => changeCategory(citem.id, 'description', val)} />

                                        <div style={{display:'flex', justifyContent:'space-between', marginTop:"5px"}}>
                                            <div className="inputLabel" style={{color:'#FFF'}}>{item.fields.UsersIncurCharge ? 'Selling Price' : 'You will receive'}: {item.fields.UsersIncurCharge ? calculateSellingPrice(citem.price) : altCalculateSellingPrice(citem.price)}</div>
                                            <button onClick={()=>setSellingDetail(true)}>
                                                <span className="sectionTopLink" style={{fontWeight:'500', fontSize:"13px", lineHeight:"16px"}}>More</span>
                                            </button>
                                        </div>
                                        {index !== (item.fields.categories.filter((dItem:generalObj) => dItem.isDeleted === false).length - 1) && (
                                            <BorderDivider borderStyle={{marginBlock:"42px"}} />
                                        )}
                                    </div>
                                ))}
                                {/* {item.fields.categories.filter((pitem:generalObj) => !pitem.isDeleted).length < 3 && ( */}
                                <div style={{marginTop:"32px"}}>
                                    <button style={{display:'flex', gap:"5px"}} onClick={()=>newTicketCat()}>
                                        <div>
                                            <IoAddOutline size={"16px"} color={"#DB012E"} />
                                        </div>
                                        <div>
                                            <div className="sectionTopLink" style={{fontSize:"13px", lineHeight:"16px"}}>Add Ticket Category</div>
                                        </div>
                                    </button>
                                </div>
                                {/* )} */}
                                
                            </div>
                        )}
                    </div>
                    
                    {!item.fields.ticketCategory && (
                        <>
                            <div style={{marginBottom:24}}>
                                <InputField id={`ticket_name`} label='Name of Ticket' inputProp={{value:item.fields.noCategory.title, type:'text', placeholder:'regular'}} labelStyle={styles.labelStyle} onTextChange={(val) => updateNoTicketName(val)} />
                            </div>

                            <div>
                                <InputField id={`num_ticket`} label='Number of Tickets' inputProp={{value:item.fields.noCategory.ticketNum, type:'number', inputMode:"numeric", placeholder:'20'}} labelStyle={styles.labelStyle} onTextChange={(val) => changeNoCategory('ticketNum', val)} />
                            </div>

                            <div style={{}}>
                                <InputField id={`price_ticket`} label='Price of Tickets' inputProp={{value:item.fields.noCategory.price, type:'number', inputMode:"numeric", placeholder:'19,400'}} customInputField={{paddingTop:0}} labelStyle={styles.labelStyle} onTextChange={(val) => changeNoCategory('price', val)} />
                            </div>

                            <div>
                                {/* Description */}
                                <InputField id={`descObj`} label='Description (optional)' multiline={true} inputProp={{value:item.fields.noCategory.description, maxLength:75}} customInputField={{paddingTop:0}} customInputText={{lineHeight:"20px", height:"50px", minHeight:"40px", maxHeight:"100px"}} labelStyle={styles.labelStyle} onTextChange={(val) => changeNoCategory('description', val)} />
                            </div>

                            <div style={{display:'flex', justifyContent:'space-between', marginTop:"5px"}}>
                                <div className="inputLabel" style={{color:'#FFF'}}>{item.fields.UsersIncurCharge ? 'Selling Price' : 'You will receive'}: {item.fields.UsersIncurCharge ? calculateSellingPrice(item.fields.noCategory.price) : altCalculateSellingPrice(item.fields.noCategory.price)}</div>
                                <button onClick={()=>setSellingDetail(true)}>
                                    <span className="sectionTopLink" style={{fontWeight:'500', fontSize:"13px", lineHeight:"16px"}}>More</span>
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}

            {addTicketCat && <PickerModal visible={openAddPicker} pickerItem={addTicketCat} selectedValue={selectedAdd? selectedAdd.value : ""} onChange={(val) => onTicketChange(val)} modalClose={()=>closeAddTicket()} />}
            {sellingDetail && <SellPriceModal visible={sellingDetail} modalClose={()=>setSellingDetail(false)} /> }
            <ScanningPeople visible={openPeople} selectedPeople={canScan} onChange={(val) => {
                setCanScan(val);
                onChange("canScan", val);
                setShowScanner(true);
                setOpenPeople(false);
            }} modalClose={() => setOpenPeople(false)} />
            {showTicketRename && ticketRenameObj && <TicketNameModal visible={showTicketRename} ticketObj={ticketRenameObj} modalClose={() => {setShowTicketRename(false); setTicketRenameObject(null)}} onComplete={(val) => updateTicketName(val, ticketRenameObj)} />}
            
        </div>
    )
}

export default NewEventTicket;

const styles = {
    labelStyle: {
        color:'#FFF'
    },
    dateText: {
        fontWeight:'400',
        fontSize:"12px",
        lineHeight:"33px",
        color:'rgba(255, 255, 255, 0.6)'
    },
    activeText: {
        color: 'rgba(255, 255, 255, 0.8)',

    }
}