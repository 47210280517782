import { useEffect, useMemo, useRef, useState } from 'react';
import { BarcodeScannerModal, BorderDivider, BottomTab, CheckInModal, EventComment, EventInfo, HeroDetails, ModalLoader, ProfileGenerator } from '../components';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { UrlType, generalObj } from '../constant/objects_types';
import { useSelector } from 'react-redux';
import { RootState } from '../redux';
import { checkEventPast, fetchEventUser, getEventDetail, postedTime, setUpPrevUrl } from '../action/generalFunc';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { RWebShare } from 'react-web-share';
import { BASE_URL, SYMBOL } from '../constant/constant';

const EventDetail = () => {
    const [modalLoader, setModalLoading] = useState<boolean>(true);
    const [openScanner, setOpenScanner] = useState<boolean>(false);
    const [openCheckIn, setOpenCheckIn] = useState<boolean>(false);
    const [refreshCheckIn, setRefreshCheckIn] = useState<boolean>(true);
    const [openComment, setOpenComment] = useState<boolean>(false);
    const [event, setEvent] = useState<generalObj | null>(null);
    const bodyRef = useRef<HTMLDivElement | null>(null);
    const [eventMinTicket, setEventMinTicket] = useState<number>(0);
    const location = useLocation();
    const account = useSelector((state:RootState) => state.account);
    const [userCanScan, setUserCanScan] = useState<boolean>(false);
    let { id } = useParams();
    const navigate = useNavigate();
    const { setTicketBuyAccount, setTicketId }:{ setTicketBuyAccount:Function, setTicketId:Function } = useOutletContext();
    const idMap:generalObj = useMemo(() => {
        return {
            "influence": 159
        };
    }, []);

    useEffect(() => {
        if (!account || !id) return;
        let id_object:string|number = idMap[id] ? idMap[id] : id; 
        (async () => {
            let result:generalObj = await fetchEventUser(account.token, `${id_object}`);

            console.log(result);
            if (result.status && result.data && result.data.data.event) {
                if (result.data.data.event.authorized_scanners.filter((scan_user:string) => account.id === parseInt(scan_user)).length > 0) {
                    setUserCanScan(true);
                }
                setEvent(result.data.data.event);
            }else {
                setEvent(null);
                alert(result.message);
            }
            if (result.status && result.data && result.data.data.event.has_ticket && result.data.data.event.tickets.length > 0) {
                let min_ticket:number = 9999999999999;
                result.data.data.event.tickets.forEach((element:generalObj) => {
                    if (min_ticket && (parseFloat(element.price) < min_ticket)) {
                        min_ticket = parseFloat(element.price);
                    }
                });
                setEventMinTicket(min_ticket);
            }else {
                setEventMinTicket(0);
            }
            setModalLoading(false);
        })();
    }, [account, id, idMap]);

    const updateEventComment = (new_comment:generalObj[]) => {
        setEvent((prevEvent) => ({...prevEvent, comments:new_comment}))
    }

    const assignTicketToPurchase = (ticket_id:number|string) => {
        setTicketBuyAccount(true); 
        setTicketId(ticket_id);
    }

    return (
        <>
            {event && (
                <div className={`appBody`}>
                    <div className='appCont' ref={bodyRef}>
                        <HeroDetails heroObj={{
                            name: event.name,
                            user: event.user,
                            cover_image: event.cover_image,
                            promotion_video: event.promotion_video,
                            event: event
                        }} parentWidth={bodyRef.current?.clientWidth} />

                        {/* Event details */}
                        <div style={{margin:"24px 20px 0px 20px"}}>
                            {event && account && <EventInfo event={event} account={account} eventMinTicket={eventMinTicket} />}

                            {/* Action Button */}
                            {event.user && account && event.user.id !== account.id && (
                                <>
                                    <div style={{marginTop:"32px", display:'flex', gap:"10px", justifyContent:'space-between'}}>
                                        {event.visibility === 'public' && (
                                            <RWebShare data={{
                                                text: `${event.description.slice(0, 100)}...`,
                                                url: `${BASE_URL}${UrlType.EVENT_DETAIL}${id}`,
                                                title: event.name,
                                            }}>
                                                <button className='postOwner buttonFrameCont heroBtn geryBtn' style={{paddingInline:userCanScan ? "14px" : ""}}>
                                                    <span className='buttonFrameText'>Share Event</span>
                                                </button>
                                            </RWebShare>
                                        )}
                                        
                                        {userCanScan && !checkEventPast(event) && (
                                            <button className='postOwner buttonFrameCont heroBtn geryBtn' style={{paddingInline:"14px"}} onClick={()=>setOpenCheckIn(true)}>
                                                <span className='buttonFrameText'>Check In</span>
                                            </button>
                                        )}
                                        
                                        {event.has_ticket && !checkEventPast(event) ? (
                                            <button  className='postOwner buttonFrameCont heroBtn' onClick={() => account && account.isAuthenticated ? navigate(setUpPrevUrl(window.location.pathname, getEventDetail(event, UrlType.BUY_TICKET))) : assignTicketToPurchase(`${getEventDetail(event, "")}`)}>
                                                <span className='buttonFrameText'>Buy Ticket</span>
                                            </button>
                                        ):null}
                                    </div>
                                </>
                            )}
                            
                            {event.user && account && event.user.id === account.id && (
                                <>
                                    <div style={{marginTop:"32px", display:'flex', gap:"8px", justifyContent:'space-between'}}>
                                        <RWebShare data={{
                                            text: `${event.description.slice(0, 100)}...`,
                                            url: `${BASE_URL}${getEventDetail(event, UrlType.EVENT_DETAIL)}`,
                                            title: event.name,
                                        }}>
                                            <button className='postOwner buttonFrameCont heroBtn geryBtn' style={{paddingInline:"14px"}}>
                                                <span className='buttonFrameText'>Share Event</span>
                                            </button>
                                        </RWebShare>
                                        
                                        {!checkEventPast(event) && (
                                            <>
                                                <button className='postOwner buttonFrameCont heroBtn geryBtn' onClick={() => navigate(`${UrlType.UPDATE_EVENT}${event.id}`)} style={{paddingInline:"14px"}}>
                                                    <span className='buttonFrameText'>Edit Event</span>
                                                </button>

                                                <button className='postOwner buttonFrameCont heroBtn geryBtn' style={{paddingInline:"14px"}} onClick={()=>setOpenCheckIn(true)}>
                                                    <span className='buttonFrameText'>Check In</span>
                                                </button>

                                                
                                            </>
                                                
                                        )}
                                    </div>
                                </>
                            )}

                            {/* Ratings */}
                            {/* {event.user && account && event.user.id !== account.id && (
                                <>
                                    <BorderDivider borderStyle={{marginVertical: 32}} />
                                    <EventRating />
                                </>
                            )} */}

                            <BorderDivider borderStyle={{marginVertical: "32px"}} />

                            {/* Comments */}
                            <div onClick={() => setOpenComment(true)}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                    <div className='sectionTitle'>Comments</div>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <span className='inputLabel' style={{fontSize:"14px", lineHeight:"24px"}}>{event && event.comments ? event.comments.length : 0}</span>
                                        <div style={{marginLeft:"5px", display:'flex', flexDirection:'column'}}>
                                            <IoChevronUpOutline size={"10px"} color='#FFF' />
                                            <IoChevronDownOutline size={"10px"} color='#FFF' />
                                        </div>
                                    </div>
                                </div>

                                <div style={{backgroundColor:'rgba(66, 66, 66, 0.25)', marginTop:"26px", borderRadius:"12px"}}>
                                    {account && account.isAuthenticated ? (
                                        <>
                                            {account && event && event.comments.length > 0 ? (
                                                <div style={{margin:"0px 16px", padding:"16px 0px", display:'flex'}}>
                                                    {event.comments[0].user.profile_picture ? (
                                                        <ProfileGenerator constStyle={{width:32, height:32}} useImage={event.comments[0].user.profile_picture} />
                                                    ): (
                                                        <ProfileGenerator title={event.comments[0].user.username[0].toUpperCase()} constStyle={{width:32, height:32}} textStyle={{fontSize:14}} useAccount={event.comments[0].user.username === account.username ? true : false} />
                                                    )}
                                                    <div style={{padding:"0px 10px", paddingRight:"0px", width:"90%"}}>
                                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                            <span className='postOwnerText' style={{color:'rgba(255, 255, 255, 0.6)', marginLeft:"0px"}}>{event.comments[0].user.fullname}</span>
                                                            <span className='inputLabel'>{postedTime(event.comments[0].created_at)}</span>
                                                        </div>
                                                        <p className='itemOwner' style={{marginTop:"5px", fontSize:"14px", lineHeight:"19px", fontWeight:'400'}}>{event.comments[0].comment}</p>
                                                    </div>
                                                </div>
                                            ): (
                                                <div style={{margin:"0px 16px", padding:"16px 0px", display:'flex', justifyContent:'center'}}>
                                                    <p className='postOwnerText' style={{color:'rgba(255, 255, 255, 0.6)'}}>Tap to add comment</p>
                                                </div>
                                            )}
                                        </>
                                    ):(
                                        <div style={{margin:"0px 16px", padding:"16px 0px", display:'flex', justifyContent:'center'}}>
                                            <p className='postOwnerText' style={{color:'rgba(255, 255, 255, 0.6)'}}>Login to add comment</p>
                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                        
                        {event.user && account && event.user.id === account.id && (
                            <div style={{marginBottom:"10px", marginTop:"42px", width:'100%', backgroundColor:"#0D0404"}}>
                                <div style={{alignItems:'center', display:'flex', flexDirection:'column', borderTop:'1px solid rgba(255, 255, 255, 0.08)', paddingTop:"12px"}}>
                                    <span className='itemOwner'>Analytics</span>
                                    <span style={{borderBottomWidth:"1px", borderBottomColor:'#FFF', width:"100%", marginTop:"12px"}}></span>
                                </div>

                                {/* Ratings */}
                                {/* <div style={{backgroundColor:'rgba(66, 66, 66, 0.25)', padding:"32px 20px"}}>
                                    <EventRating />
                                </div> */}

                                {/* Ticket Summary */}
                                <div style={{padding:"32px 20px", marginTop:"32px", backgroundColor:'rgba(66, 66, 66, 0.25)'}}>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                        <span className='sectionTitle' style={{fontSize:"14px", lineHeight:"16px"}}>Ticket Summary</span>
                                    </div>
                                    <div style={{marginTop:"16px"}}>
                                        <div style={{display:'flex', justifyContent:'space-between', gap:"15px"}}>
                                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.16)', display:'flex', flexDirection:'column', padding:"14px", width:'50%', justifyContent:'center', alignItems:'center', borderRadius:"7px"}}>
                                                <span className='buttonFrameText' style={{fontSize:"18px", lineHeight:"25px"}}>{parseFloat(`${event.tickets_summary.total_tickets_sold}`).toLocaleString()}</span>
                                                <span className='locationRegion'>Ticket Sold</span>
                                            </div>
                                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.16)', display:'flex', flexDirection:'column', padding:"14px", width:'50%', justifyContent:'center', alignItems:'center', borderRadius:"7px"}}>
                                                <span className='buttonFrameText' style={{fontSize:"18px", lineHeight:"25px"}}>{`${SYMBOL}${parseFloat(`${event.tickets_summary.total_amount_made}`).toLocaleString()}`}</span>
                                                <span className='locationRegion'>Amount Made</span>
                                            </div>
                                        </div>
                                        <div style={{height:"1px", width:'100%', margin:"32px 0px", backgroundColor:'rgba(255, 255, 255, 0.08)'}}></div>
                                        <div style={{justifyContent:'center'}}>
                                            {event.tickets_summary.by_type.map((item:generalObj, index:number) => (
                                                <div key={index} style={{display:'flex', marginBottom:"12px", alignItems:'center', justifyContent:'space-between'}}>
                                                    <div style={{width:"100px"}}>
                                                        <span className='toggleFilterItemText' style={{fontSize:"14px", wordBreak:'break-word'}}>{item.category === 'vvip' ? "V.V.I.P" : item.category === 'vip' ? "V.I.P" : item.category === 'regular' ? 'Regular' : item.title}</span>
                                                    </div>
                                                    <div style={{height:"2px", borderRadius:"5px", margin:"0px 8px", flex:1, backgroundColor:'rgba(255, 255, 255, 0.08)', position:"relative"}}>
                                                        <div style={{height:"2px", borderRadius:"5px", position:"absolute", left:0, top:0, width:`${(item.tickets_sold / item.total) * 100}%`, backgroundColor:'#DB012E'}}>
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:'column', alignItems:'flex-end'}}>
                                                        <span className='locationRegion' style={{fontWeight:'400'}}>{item.tickets_sold} <span style={{color:'rgba(255, 255, 255, 0.6)'}}>Tickets</span></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    
                                </div>

                                {/* attendance Summary */}
                                <div style={{padding:"32px 20px", marginTop:"32px", backgroundColor:'rgba(66, 66, 66, 0.25)'}}>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                        <span className='sectionTitle' style={{fontSize:"14px", lineHeight:"16px"}}>Attendance Summary</span>
                                    </div>
                                    <div style={{marginTop:"16px"}}>
                                        <div style={{display:'flex', justifyContent:'space-between', gap:"15px"}}>
                                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.16)', display:'flex', flexDirection:'column', padding:"14px", width:'50%', justifyContent:'center', alignItems:'center', borderRadius:"7px"}}>
                                                <span className='buttonFrameText' style={{fontSize:"18px", lineHeight:"25px"}}>{parseFloat(`${event.attendance_summary.checked_in}`).toLocaleString()}</span>
                                                <span className='locationRegion'>Checked in</span>
                                            </div>
                                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.16)', display:'flex', flexDirection:'column', padding:"14px", width:'50%', justifyContent:'center', alignItems:'center', borderRadius:"7px"}}>
                                                <span className='buttonFrameText' style={{fontSize:"18px", lineHeight:"25px"}}>{parseFloat(`${event.attendance_summary.expected_attendees}`).toLocaleString()}</span>
                                                <span className='locationRegion'>Expected Attendees</span>
                                            </div>
                                        </div>
                                        <div style={{height:"1px", width:'100%', margin:"32px 0px", backgroundColor:'rgba(255, 255, 255, 0.08)'}}></div>
                                        <div style={{justifyContent:'center'}}>
                                            {event.attendance_summary.by_type.map((item:generalObj, index:number) => (
                                                <div key={index} style={{display:'flex', marginBottom:"12px", alignItems:'center', justifyContent:'space-between'}}>
                                                    <div style={{width:"100px"}}>
                                                        <span className='toggleFilterItemText' style={{fontSize:"14px", wordBreak:'break-word'}}>{item.category === 'vvip' ? "V.V.I.P" : item.category === 'vip' ? "V.I.P" : item.category === 'regular' ? "Regular" : item.title}</span>
                                                    </div>
                                                    <div style={{height:"2px", borderRadius:"5px", margin:"0px 8px", flex:1, backgroundColor:'rgba(255, 255, 255, 0.08)', position:"relative"}}>
                                                        <div style={{height:"2px", borderRadius:"5px", position:"absolute", left:0, top:0, width:`${(item.tickets_sold / item.total) * 100}%`, backgroundColor:'#DB012E'}}>
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:'column', alignItems:'flex-end'}}>
                                                        <span className='locationRegion' style={{fontWeight:'400'}}>{item.tickets_sold} <span style={{color:'rgba(255, 255, 255, 0.6)'}}>Tickets</span></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            
            <BottomTab location_path={location.pathname} />
            {event && <CheckInModal visible={openCheckIn} modalClose={() => {setOpenCheckIn(false); setRefreshCheckIn(true)}} event={event} openBarcode={() => setOpenScanner(true)} isRefresh={refreshCheckIn} toggleRefresh={() => setRefreshCheckIn(false)} /> }
            {openScanner &&  <BarcodeScannerModal visible={openScanner} modalClose={() => {setOpenScanner(false); setRefreshCheckIn(true)}} /> }
            {id && event && (
                <EventComment visible={openComment} event_id={id} eventObj={event} setComments={(val) => updateEventComment(val)} modalClose={()=>setOpenComment(false)} />
            )}
            
            <ModalLoader visible={modalLoader} />

        </>
    )
    
}

export default EventDetail;